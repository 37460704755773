<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header p-0 border-bottom-0">
                    <h4 class="card-title pt-3 custom-padding mb-3">{{ title }}</h4>
                    <div class="row">
                        <div class="col-12">
                            <ul
                                class="nav nav-tabs nav-tabs-custom nav-justified"
                                role="tablist"
                            >
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        :class="{active: $route.name === 'partner-projects', disabled: create}"
                                        :to="{path: $t('routes./partners/:id(\\d+)/projects/:type?', {id: $route.params.id, type: $route.params.type}).rmValidation() }"
                                    >
                                        <span class="d-block d-sm-none">
                                            <i class="fas fa-clipboard-list"></i>
                                        </span>
                                        <span class="d-none d-sm-block">{{
                                                $tc('projects::projects.project', 2).ucFirst()
                                            }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        :class="{active: $route.name === 'partner-base-information', disabled: create}"
                                        :to="{path: $t('routes./partners/:id(\\d+)/base-information/:type?', {id: $route.params.id, type: $route.params.type}).rmValidation() }"
                                    >
                                        <span class="d-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-block">{{
                                                $t('base.base_information').ucFirst()
                                            }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        :class="{active: $route.name === 'partner-contacts', disabled: create}"
                                        :to="{path: $t('routes./partners/:id(\\d+)/contacts/:type?', {id: $route.params.id, type: $route.params.type}).rmValidation() }"
                                    >
                                        <span class="d-block d-sm-none">
                                            <i class="fas fa-address-card"></i>
                                        </span>
                                        <span class="d-none d-sm-block">{{
                                                $t('partners::partners.relationships.contacts').ucFirst()
                                            }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        :class="{active: $route.name === 'partner-decision-makers', disabled: create}"
                                        :to="{path: $t('routes./partners/:id(\\d+)/decision-makers/:type?', {id: $route.params.id, type: $route.params.type}).rmValidation() }"
                                    >
                                        <span class="d-block d-sm-none">
                                            <i class="fas fa-address-card"></i>
                                        </span>
                                        <span class="d-none d-sm-block">{{
                                                $t('partners::partners.relationships.decision_makers').ucFirst()
                                            }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        :class="{active: $route.name === 'partner-addresses', disabled: create}"
                                        :to="{path: $t('routes./partners/:id(\\d+)/addresses/:type?', {id: $route.params.id, type: $route.params.type}).rmValidation() }"
                                    >
                                        <span class="d-block d-sm-none">
                                            <i class="fas fa-map-marker-alt"></i>
                                        </span>
                                        <span class="d-none d-sm-block">{{
                                                $t('partners::partners.relationships.addresses').ucFirst()
                                            }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-body" :class="{'bg-light': $route.name === 'partner-projects'}">
                    <div class="row">
                        <div class="col-12">
                            <div class="tab-content p-3 text-muted">
                                <div class="tab-pane active" role="tabpanel">
                                    <router-view
                                        v-if="!create"
                                        :partner="partner"
                                        :contact="contact"
                                        :address="address"
                                        :partner-id="partner?.id"
                                    ></router-view>
                                    <edit-page v-else :partner="partner"></edit-page>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
// import PartnerForm from "../../../components/forms/partner-form.vue";
import EditPage from "./edit.vue"

export default {
    components: {EditPage},

    computed: {
        ...mapGetters({
            partner: 'partner/item',
            users: 'user/all',
            companySizes: 'companySize/all',
            leadSources: 'leadSource/all',
            industries: 'industry/all',
            contact: 'contact/item',
            address: 'address/item',
            fieldVersions: 'fieldVersion/all'
        }),

        title: function () {
            return this.partner && this.partner.id ? this.$tc('partners::partners.partner', 2).ucFirst() + ' / ' + this.partner.name : this.$t('base.create_new_item', {item: this.$tc('partners::partners.partner', 1)}).ucFirst()
        },

        create: function () {
            return !this.$route.params.id
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    created() {
        if (!this.users || (this.users && !this.users.length)) {
            this.$store.dispatch('user/all')
        }
        if (!this.companySizes || (this.companySizes && !this.companySizes.length)) {
            this.$store.dispatch('companySize/all')
        }
        if (!this.leadSources || (this.leadSources && !this.leadSources.length)) {
            this.$store.dispatch('leadSource/all')
        }
        if (!this.industries || (this.industries && !this.industries.length)) {
            this.$store.dispatch('industry/all')
        }
        if (!this.fieldVersions || (this.fieldVersions && !this.fieldVersions.length)) {
            this.$store.dispatch('fieldVersion/all')
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('partner/show', {id: this.$route.params.id})
                .catch(() => {
                    this.$router.push('/')
                })
        }
    },

    unmounted() {
        this.$store.dispatch('partner/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>
.custom-padding {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
</style>
