<script>
import PartnerForm from "../../../components/forms/partner-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";

export default {
    props: {
        partner: {
            type: Object
        }
    },

    components: {CallbackButton, PartnerForm},

    computed: {
        link: function () {
            switch (this.$route.params.type) {
                case this.$t('partners::partners.types.my'):
                    return {path: this.$t('routes.' + '/partners/my-partners')}
                case this.$t('partners::partners.types.all'):
                    return {path: this.$t('routes.' + '/partners/all')}
                case this.$t('partners::partners.types.free'):
                    return {path: this.$t('routes.' + '/partners/free')}
            }

            return {path: this.$t('routes.' + '/partners/all')}
        },

        useAuthenticatedAsDefault: function () {
            return this.$route.params.type === this.$t('partners::partners.types.my')
        },
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },
}
</script>

<template>
    <partner-form :partner="partner" :use-authenticated-as-default="useAuthenticatedAsDefault">
        <template #buttons="{save}">
            <callback-button
                class="ms-2"
                :method="save"
                :callback="back"
            ></callback-button>
        </template>
    </partner-form>
</template>

<style scoped>

</style>
